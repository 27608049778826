/*
    C-SKIN EXTENSION
    Extension du composant générique c-skin
    https://github.com/ita-design-system/c-skin.scss
    Ce fichier doit servir à étendre ou surcharger les fonctionnalités
    du composant c-dim selon les besoins du projet
*/
$briks-components-generic: (
    // Nom du composant, obligatoirement skin
    skin: ( 
        // Extension activée true ou false
        enabled: true, 
        // Responsive activée true ou false pour l'extension
        responsive: false, 
        // Valeurs par défaut de l'extension
        defaults: (
            pseudo-classes: (
                // c-skin:disabled
                disabled: (
                    pointer-events: none,
                    color: grey,
                    background-color: lightgrey
                )
            )
        ),
        // Liste des modifieurs contenant chacun une liste de propriétés qui 
        // soit surchargent les propriétés par défaut
        // soit ajoutent des propriétés
        // soit les deux
        modifiers: ( 
            bimage-1: (
                border-image: url('../medias/border-1.svg') 13 / 10px
            ),
            // BORDER WIDTHS
            bwidth-4: (
                border-width: 4px
            ),
            bwidth-5: (
                border-width: 5px
            ),
            bwidth-6: (
                border-width: 6px
            ),

            // BORDER COLORS
            // c-skin m-bcolor-blue-500
            bcolor-blue-500: (
                border-color: my-color(blue-500)
            ),
            // c-skin m-btcolor-blue-500
            btcolor-blue-500: (
                border-top-color: my-color(blue-500)
            ),
            // c-skin m-brcolor-blue-500
            brcolor-blue-500: (
                border-right-color: my-color(blue-500)
            ),
            // c-skin m-bbcolor-blue-500
            bbcolor-blue-500: (
                border-bottom-color: my-color(blue-500)
            ),
            // c-skin m-blcolor-blue-500
            blcolor-blue-500: (
                border-left-color: my-color(blue-500)
            ),

            // c-skin m-bcolor-blue-600
            bcolor-blue-600: (
                border-color: my-color(blue-600)
            ),
            // c-skin m-btcolor-blue-600
            btcolor-blue-600: (
                border-top-color: my-color(blue-600)
            ),
            // c-skin m-brcolor-blue-600
            brcolor-blue-600: (
                border-right-color: my-color(blue-600)
            ),
            // c-skin m-bbcolor-blue-600
            bbcolor-blue-600: (
                border-bottom-color: my-color(blue-600)
            ),
            // c-skin m-blcolor-blue-600
            blcolor-blue-600: (
                border-left-color: my-color(blue-600)
            ),

            // c-skin m-bcolor-orange-500
            bcolor-orange-500: (
                border-color: my-color(orange-500)
            ),
            // c-skin m-btcolor-orange-500
            btcolor-orange-500: (
                border-top-color: my-color(orange-500)
            ),
            // c-skin m-brcolor-orange-500
            brcolor-orange-500: (
                border-right-color: my-color(orange-500)
            ),
            // c-skin m-bbcolor-orange-500
            bbcolor-orange-500: (
                border-bottom-color: my-color(orange-500)
            ),
            // c-skin m-blcolor-orange-500
            blcolor-orange-500: (
                border-left-color: my-color(orange-500)
            ),

            // c-skin m-bcolor-lime-500
            bcolor-lime-500: (
                border-color: my-color(lime-500)
            ),
            // c-skin m-btcolor-lime-500
            btcolor-lime-500: (
                border-top-color: my-color(lime-500)
            ),
            // c-skin m-brcolor-lime-500
            brcolor-lime-500: (
                border-right-color: my-color(lime-500)
            ),
            // c-skin m-bbcolor-lime-500
            bbcolor-lime-500: (
                border-bottom-color: my-color(lime-500)
            ),
            // c-skin m-blcolor-lime-500
            blcolor-lime-500: (
                border-left-color: my-color(lime-500)
            ),

            // c-skin m-bcolor-purple-500
            bcolor-purple-500: (
                border-color: my-color(purple-500)
            ),
            // c-skin m-btcolor-purple-500
            btcolor-purple-500: (
                border-top-color: my-color(purple-500)
            ),
            // c-skin m-brcolor-purple-500
            brcolor-purple-500: (
                border-right-color: my-color(purple-500)
            ),
            // c-skin m-bbcolor-purple-500
            bbcolor-purple-500: (
                border-bottom-color: my-color(purple-500)
            ),
            // c-skin m-blcolor-purple-500
            blcolor-purple-500: (
                border-left-color: my-color(purple-500)
            ),

            // c-skin m-bcolor-grey-light-100
            bcolor-grey-light-100: (
                border-color: my-color(grey-light-100)
            ),
            // c-skin m-btcolor-grey-light-100
            btcolor-grey-light-100: (
                border-top-color: my-color(grey-light-100)
            ),
            // c-skin m-brcolor-grey-light-100
            brcolor-grey-light-100: (
                border-right-color: my-color(grey-light-100)
            ),
            // c-skin m-bbcolor-grey-light-100
            bbcolor-grey-light-100: (
                border-bottom-color: my-color(grey-light-100)
            ),
            // c-skin m-blcolor-grey-light-100
            blcolor-grey-light-100: (
                border-left-color: my-color(grey-light-100)
            ),

            // c-skin m-bcolor-grey-light-500
            bcolor-grey-light-500: (
                border-color: my-color(grey-light-500)
            ),
            // c-skin m-btcolor-grey-light-500
            btcolor-grey-light-500: (
                border-top-color: my-color(grey-light-500)
            ),
            // c-skin m-brcolor-grey-light-500
            brcolor-grey-light-500: (
                border-right-color: my-color(grey-light-500)
            ),
            // c-skin m-bbcolor-grey-light-500
            bbcolor-grey-light-500: (
                border-bottom-color: my-color(grey-light-500)
            ),
            // c-skin m-blcolor-grey-light-500
            blcolor-grey-light-500: (
                border-left-color: my-color(grey-light-500)
            ),
            // c-skin m-btcolor-grey-light-600
            btcolor-grey-light-600: (
                border-top-color: my-color(grey-light-600)
            ),
            // c-skin m-brcolor-grey-light-600
            brcolor-grey-light-600: (
                border-right-color: my-color(grey-light-600)
            ),
            // c-skin m-bbcolor-grey-light-600
            bbcolor-grey-light-600: (
                border-bottom-color: my-color(grey-light-600)
            ),
            // c-skin m-blcolor-grey-light-600
            blcolor-grey-light-600: (
                border-left-color: my-color(grey-light-600)
            ),
            // c-skin m-bcolor-grey-light-800
            bcolor-grey-light-800: (
                border-color: my-color(grey-light-800)
            ),
            // c-skin m-btcolor-grey-light-800
            btcolor-grey-light-800: (
                border-top-color: my-color(grey-light-800)
            ),
            // c-skin m-brcolor-grey-light-800
            brcolor-grey-light-800: (
                border-right-color: my-color(grey-light-800)
            ),
            // c-skin m-bbcolor-grey-light-800
            bbcolor-grey-light-800: (
                border-bottom-color: my-color(grey-light-800)
            ),
            // c-skin m-blcolor-grey-light-800
            blcolor-grey-light-800: (
                border-left-color: my-color(grey-light-800)
            ),

            // c-skin m-bcolor-grey-dark-500
            bcolor-grey-dark-500: (
                border-color: my-color(grey-dark-500)
            ),
            // c-skin m-btcolor-grey-dark-500
            btcolor-grey-dark-500: (
                border-top-color: my-color(grey-dark-500)
            ),
            // c-skin m-brcolor-grey-dark-500
            brcolor-grey-dark-500: (
                border-right-color: my-color(grey-dark-500)
            ),
            // c-skin m-bbcolor-grey-dark-500
            bbcolor-grey-dark-500: (
                border-bottom-color: my-color(grey-dark-500)
            ),
            // c-skin m-blcolor-grey-dark-500
            blcolor-grey-dark-500: (
                border-left-color: my-color(grey-dark-500)
            ),

            // c-skin m-bcolor-grey-dark-900
            bcolor-grey-dark-900: (
                border-color: my-color(grey-dark-900)
            ),
            // c-skin m-btcolor-grey-dark-900
            btcolor-grey-dark-900: (
                border-top-color: my-color(grey-dark-900)
            ),
            // c-skin m-brcolor-grey-dark-900
            brcolor-grey-dark-900: (
                border-right-color: my-color(grey-dark-900)
            ),
            // c-skin m-bbcolor-grey-dark-900
            bbcolor-grey-dark-900: (
                border-bottom-color: my-color(grey-dark-900)
            ),
            // c-skin m-blcolor-grey-dark-900
            blcolor-grey-dark-900: (
                border-left-color: my-color(grey-dark-900)
            ),
            // c-skin m-bg-select
            bg-select: (
                background: right my-spacing(4) top 50% / my-font-size(2) no-repeat my-color(grey-light-100) url("https://cdn.jsdelivr.net/gh/ita-design-system/ug-releases@main/ui/medias/chevron_down.svg")
            ),
            // c-skin m-bg-1
            bg-1: (
                background: 50% 95% / contain no-repeat my-color(grey-light-100) url(../medias/menu.svg)
            ),
            // c-skin m-bg-2
            bg-2: (
                background: (url(../medias/vision.svg) no-repeat left my-spacing(10) top 27%,
                linear-gradient(to bottom, my-color(purple-500) 53%, white 53.1%))
            ),
            // c-skin m-bg-3
            bg-3: (
                background: (url(../medias/vision.svg) no-repeat left my-spacing(5) top 53%,
                linear-gradient(to bottom, my-color(purple-500) 68%, white 68.1%))
            ),
            // c-skin m-bg-4
            bg-4: (
                background: left my-spacing(3) top 50% / my-font-size(6) no-repeat url(../medias/search_line.svg)
            ),
            // c-skin m-bg-5
            bg-5: (
                background: url(../medias/ucar-store.svg) center bottom no-repeat
            ),
            // c-skin m-bg-6
            bg-6: (
                background: (url(../medias/mobilite.svg) no-repeat left my-spacing(10) top 15%,
                linear-gradient(to bottom, my-color(orange-500) 53%, white 53.1%))
            ),
            // c-skin m-bg-7
            bg-7: (
                background: (url(../medias/mobilite.svg) no-repeat left my-spacing(5) top 37%,
                linear-gradient(to bottom, my-color(orange-500) 65%, white 65.1%))
            ),
            // c-skin m-bg-8
            bg-8: (
                background: url(../medias/landscape.svg) center bottom no-repeat
            ),
            // c-skin m-bg-9
            bg-9: (
                background: (url(../medias/groupe.svg) no-repeat left my-spacing(10) top 35%,
                linear-gradient(to bottom, my-color(purple-500) 53%, white 53.1%))
            ),
            // c-skin m-bg-10
            bg-10: (
                background: (url(../medias/groupe.svg) no-repeat left my-spacing(5) top 52%,
                linear-gradient(to bottom, my-color(purple-500) 65%, white 65.1%))
            ),
            // c-skin m-bg-11
            bg-11: (
                background: url(../medias/groupe_illustration.svg) center bottom no-repeat
            ),
            // c-skin m-bg-12
            bg-12: (
                background: top 0% right 0% / auto 200px no-repeat url('../medias/ucar-en-chiffres-1_2.svg')
            ),
            // c-skin m-bg-13
            bg-13: (
                background: bottom 0% center / auto 390px no-repeat url('../medias/ucar-en-chiffres-2_2.svg')
            ),
            // c-skin m-bg-14
            bg-14: (
                background: top 50% left 0% / auto 80% no-repeat url('../medias/ucar-generic.svg')
            ),
            // c-skin m-bg-15
            bg-15: (
                background: left 0% center/auto 314px no-repeat url("../medias/ucar-concept.svg")
            ),
            // c-skin m-bg-16
            bg-16: (
                background: repeat-x url('../medias/banner.svg')
            ),
            // c-skin m-bg-17
            bg-17: (
                background: top 24px left 24px no-repeat url('../medias/garage.svg')
            ),
            // c-skin m-bg-18
            bg-18: (
                background: url('../medias/franchise.svg') no-repeat center / contain
            ),
            // c-skin m-bg-19
            bg-19: (
                background: top 50% left 0% / auto 85% no-repeat url('../medias/reussite.svg')
            ),
            // c-skin m-bg-20
            bg-20: (
                background: linear-gradient(to bottom, transparent 20%, my-color(blue-500) 20.1%)
            ),
            // c-skin m-bg-21
            bg-21: (
                background: linear-gradient(to bottom, transparent 14%, my-color(blue-500) 14.1%)
            ),
            // c-skin m-bg-22
            bg-22: (
                background: url(../../ui/medias/info.svg) no-repeat
            ),
            // c-skin m-bg-23
            bg-23: (
                background: url(../../ui/medias/join.svg) no-repeat
            ),
            // c-skin m-bg-24
            bg-24: (
                background: top 22% right url(../../ui/medias/investment.svg) no-repeat
            ),
            // c-skin m-bg-24
            bg-25: (
                background: top 32% left 0% url(../../ui/medias/contact.svg) no-repeat
            ),
            // c-skin m-bg-24
            bg-26: (
                background-color: rgba(64,64,64, 0.8)
            ),
            // c-skin m-bg-27
            bg-27: (
                background: top 22% right 152% url(../../ui/medias/investment.svg) no-repeat
            ),
            // c-skin m-bg-28
            bg-28: (
                background: top 38% right 74% url(../../ui/medias/investment.svg) no-repeat
            ),
            // c-skin m-bg-29
            bg-29: (
                background: center/contain url(../../ui/medias/mep_hp.svg) no-repeat
            ),
            // c-skin m-bg-30
            bg-30: (
                background: url(../../ui/medias/congratulations-car.png) left center no-repeat
            ),
            // c-skin m-bg-31
            bg-31: (
                background: top 38% right 100%  url('../../ui/medias/congratulations-ucar.png') no-repeat
            ),
            // c-skin m-bg-32
            bg-32: (
                background: url(../../ui/medias/mep_car.png) top 50% left 50% no-repeat,
                background-size: contain
            ),
            // c-skin m-bg-33
            bg-33: (
                background: url(../../ui/medias/car_availability.svg) top 50% left 50% no-repeat,
                background-size: contain
            ),
            // c-skin m-bg-34
            bg-34: (
                background: url(../../ui/medias/guy.svg) top 10% left 50% no-repeat,
                background-size: contain
            ),
            // c-skin m-bg-35
            bg-35: (
                background: url(../../ui/medias/bg_account.svg) left center no-repeat
            ),
            // c-skin m-bg-36
            bg-36: (
                background: url(../../ui/medias/faq.svg) top 50% left 50% no-repeat,
                background-size: cover
            ),
            // c-skin m-bg-37
            bg-37: (
                background: top 30% right 0% url(../../ui/medias/mep_contact.svg) no-repeat,
                background-size: contain
            ),
            // c-skin m-bg-38
            bg-38: (
                background: (url(../medias/actu.svg) no-repeat left my-spacing(10) top 20%,
                linear-gradient(to bottom, my-color(lime-500) 53%, white 53.1%))
            ),
            // c-skin m-bg-39
            bg-39: (
                background: (url(../medias/actu.svg) no-repeat left my-spacing(5) top 41%,
                linear-gradient(to bottom, my-color(lime-500) 65%, white 65.1%))
            ),
            // c-skin m-bg-40
            bg-40: (
                background: url(../medias/news.svg) center bottom no-repeat
            ),
            // c-skin m-bg-41
            bg-41: (
                background: url(../medias/book.svg) left 50% top 0%  no-repeat
            ),
            // c-skin m-bg-42
            bg-42: (
                background: url(../medias/book.svg) right bottom 20% no-repeat
            ),
            // c-skin m-bg-43
            bg-43: (
                background: url(../medias/phone.svg) center no-repeat
            ),
            // c-skin m-bg-44
            bg-44: (
                background: center / contain no-repeat url(../../ui/medias/groupe-missions.svg)
            ),
            // c-skin m-bg-45
            bg-45: (
                background: center / contain no-repeat url(../../ui/medias/groupe-missions-alt.svg)
            ),
            // c-skin m-bg-46
            bg-46: (
                background: center / cover no-repeat url(../../ui/medias/article-cover.jpg)
            ),
            // c-skin m-bg-47
            bg-47: (
                background: (url(../medias/join-us.svg) no-repeat left my-spacing(10) top 32%,
                linear-gradient(to bottom, my-color(grey-dark-700) 53%, white 53.1%))
            ),
            // c-skin m-bg-48
            bg-48: (
                background: (url(../medias/join-us.svg) no-repeat left my-spacing(5) top 50%,
                linear-gradient(to bottom, my-color(grey-dark-700) 65%, white 65.1%))
            ),
            // c-skin m-bg-49
            bg-49: (
                background: url(../medias/join-illustration.svg) center bottom no-repeat
            ),
            // c-skin m-bg-50
            bg-50: (
                background: left top / contain no-repeat url(https://raw.githubusercontent.com/ita-design-system/ug-releases/aa6be2362914f0adc88072db750d122c2eafa2fa/ui/medias/map_opportunites.svg)
            ),
            // c-skin m-anim-1
            anim-1: (
                animation: banner 20s linear forwards infinite
            ),

            // c-skin m-checkbox-1
            checkbox-1: (
                pseudo-elements: (
                    before: (
                        selector: '+*',
                        content: '',
                        border-width: 1px,
                        border-style: solid,
                        border-color: my-color(grey-dark-500),
                        background: my-color(grey-light-100),
                        border-radius: my-border-radius(1)
                    )
                ),
                pseudo-classes: (
                    disabled: (
                        selector: '+*',
                        opacity: 0.3
                    ),
                    checked: (
                        selector: '+*',
                        pseudo-elements: (
                            before: (
                                border-color: my-color(blue-600),
                                background: center / 80% my-color(blue-600) no-repeat url('../medias/checkbox_check.svg')
                            )
                        )
                    )
                )
            ),
            // c-skin m-radio-1
            radio-1: (
                pseudo-elements: (
                    before: (
                        selector: '+*',
                        content: '',
                        border-width: 1px,
                        border-style: solid,
                        border-color: my-color(grey-dark-500),
                        background: my-color(grey-light-100),
                        border-radius: 100%
                    )
                ),
                pseudo-classes: (
                    disabled: (
                        selector: '+*',
                        opacity: 0.3
                    ),
                    checked: (
                        selector: '+*',
                        pseudo-elements: (
                            before: (
                                border-color: my-color(blue-600),
                                background: radial-gradient(circle, my-color(blue-600) 40%, my-color(grey-light-100) 46%, my-color(grey-light-100) 100%)
                            )
                        )
                    )
                )
            ),
            // c-skin m-color-scheme-1
            color-scheme-1: (
                background-color: my-color(blue-600),
                color: my-color(grey-light-100),
                pseudo-classes: (
                    hover: (
                        background-color: my-color(blue-700)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-light-100), 0 0 0 4px my-color(blue-600))
                    ),
                    active: (
                        outline: none,
                        background-color: my-color(blue-800)
                    ),
                )
            ),
            // c-skin m-color-scheme-2
            color-scheme-2: (
                background-color: my-color(grey-dark-900),
                color: my-color(grey-light-100),
                border-color: my-color(grey-dark-900),
                pseudo-classes: (
                    hover: (
                        background-color: my-color(grey-dark-700)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-light-100), 0 0 0 4px my-color(grey-dark-700))
                    ),
                    active: (
                        outline: none,
                        background-color: my-color(grey-dark-700)
                    ),
                )
            ),
            // c-skin m-color-scheme-3
            color-scheme-3: (
                color: my-color(blue-600),
                pseudo-classes: (
                    hover: (
                        color: my-color(blue-600),
                    ),
                    active: (
                        color: my-color(blue-700)
                    ),
                    visited: (
                        color: my-color(purple-700)
                    )
                )
            ),
            // c-skin m-color-scheme-4
            color-scheme-4: (
                background-color: my-color(grey-light-100),
                color: my-color(grey-dark-900),
                border-color: my-color(grey-light-100),
                pseudo-classes: (
                    hover: (
                        background-color: my-color(grey-light-300)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-dark-900), 0 0 0 4px my-color(grey-light-100))
                    ),
                    active: (
                        outline: none,
                        background-color: my-color(grey-light-300)
                    )
                )
            ),
            // c-skin m-color-scheme-5
            color-scheme-5: (
                color: my-color(grey-dark-900),
                background-color: my-color(grey-light-100),
                border: 1px solid my-color(grey-dark-900),
                pseudo-classes: (
                    disabled: (
                        color: my-color(grey-light-500),
                        border-color: my-color(grey-light-500),
                    ),
                    hover: (
                        background-color: my-color(grey-light-500)
                    ),
                    focus: (
                        outline: none,
                        color: my-color(grey-light-100),
                        background-color: my-color(grey-dark-900),
                        box-shadow: (0 0 0 2px my-color(grey-light-100), 0 0 0 4px my-color(grey-dark-900))
                    ),
                    active: (
                        color: my-color(grey-light-100),
                        background-color: my-color(grey-dark-900),
                    )
                )
            ),
            // c-skin m-color-scheme-6 (facebook)
            color-scheme-6: (
                color: my-color(grey-light-700),
                pseudo-classes: (
                    hover: (
                        color: #3578EA
                    )
                )
            ),
            // c-skin m-color-scheme-7 (Instagram)
            color-scheme-7: (
                color: my-color(grey-light-700),
                pseudo-classes: (
                    hover: (
                        color: #FF6AAC
                    )
                )
            ),
            // c-skin m-color-scheme-8 (Youtube)
            color-scheme-8: (
                color: my-color(grey-light-700),
                pseudo-classes: (
                    hover: (
                        color: #EB3F25
                    )
                )
            ),
            // c-skin m-color-scheme-9 (LinkedIn)
            color-scheme-9: (
                color: my-color(grey-light-700),
                pseudo-classes: (
                    hover: (
                        color: #67B8FF
                    )
                )
            ),
            // c-skin m-color-scheme-10
            color-scheme-10: (
                border-color: my-color(grey-light-800),
                background-color: my-color(grey-light-100),
                pseudo-classes: (
                    hover: (
                        border-color: my-color(grey-dark-900)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-light-100), 0 0 0 4px my-color(blue-600))
                    ),
                    required: (
                        selector: '+label',
                        pseudo-elements: (
                            after: (
                                content: " *",
                                color: red
                            )
                        )
                    ),
                    disabled: (
                        color: my-color(grey-light-500),
                        border-color: my-color(grey-light-500),
                        background-color: my-color(grey-light-100)
                    )
                )
            ),
            // c-skin m-color-scheme-10-ext-1
            color-scheme-10-ext-1: (
                pseudo-classes: (
                    disabled: (
                        selector: '+label',
                        color: my-color(grey-light-500)
                    )
                )
            ),
            // c-skin m-color-scheme-10-ext-2
            color-scheme-10-ext-2: (
                pseudo-classes: (
                    disabled: (
                        selector: '+label+*',
                        color: my-color(grey-light-500)
                    )
                )
            ),
            // c-skin m-color-scheme-11background-color: my-color(grey-light-100)
            color-scheme-11: (
                background-color: my-color(orange-500),
                color: my-color(grey-dark-900),
                pseudo-classes: (
                    hover: (
                        background-color: my-color(orange-600)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-light-100), 0 0 0 4px my-color(orange-600))
                    )
                )
            ),
            // c-skin m-color-scheme-12
            color-scheme-12: (
                background-color: my-color(grey-light-100),
                color: my-color(grey-dark-500),
                border-color: my-color(grey-light-100),
                pseudo-classes: (
                    hover: (
                        background-color: my-color(grey-light-300)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-dark-900), 0 0 0 4px my-color(grey-light-100))
                    ),
                    active: (
                        outline: none,
                        background-color: my-color(grey-light-300)
                    )
                )
            ),
            // c-skin m-color-scheme-13
            color-scheme-13: (
                background-color: my-color(grey-light-100),
                color: my-color(purple-500),
                border-color: my-color(grey-light-100),
                pseudo-classes: (
                    hover: (
                        background-color: my-color(grey-light-300)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-dark-900), 0 0 0 4px my-color(grey-light-100))
                    ),
                    active: (
                        outline: none,
                        background-color: my-color(grey-light-300)
                    )
                )
            ),
            // c-skin m-color-scheme-14
            color-scheme-14: (
                background-color: my-color(grey-light-100),
                pseudo-classes: (
                    hover: (
                        border-color: my-color(grey-dark-900)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-light-100), 0 0 0 4px my-color(orange-600))
                    ),
                    disabled: (
                        color: my-color(grey-light-500),
                        border-color: my-color(grey-light-500),
                        background-color: my-color(grey-light-100)
                    )
                )
            ),
            // c-skin m-color-scheme-15
            color-scheme-15: (
                color: my-color(orange-500),
                background-color: my-color(grey-light-100),
                border: 1px solid my-color(grey-dark-100),
                pseudo-classes: (
                    disabled: (
                        color: my-color(grey-light-500),
                        border-color: my-color(grey-light-500),
                    ),
                    hover: (
                        background-color: my-color(grey-light-500)
                    ),
                    focus: (
                        outline: none,
                        color: my-color(grey-light-100),
                        background-color: my-color(orange-500),
                        box-shadow: (0 0 0 2px my-color(grey-light-100), 0 0 0 4px my-color(orange-500))
                    ),
                    active: (
                        color: my-color(grey-light-100),
                        background-color: my-color(orange-500),
                    )
                )
            ),
            // c-skin m-color-scheme-16
            color-scheme-16: (
                background-color: my-color(grey-light-100),
                color: my-color(grey-dark-500),
                border-color: my-color(purple-500),
                pseudo-classes: (
                    hover: (
                        background-color: my-color(purple-100),
                        color: my-color(purple-700),
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-dark-900), 0 0 0 4px my-color(grey-light-100))
                    ),
                    active: (
                        outline: none,
                        background-color: my-color(purple-300),
                        color: my-color(purple-700),
                    )
                )
            ),
            // c-skin m-color-scheme-17
            color-scheme-17: (
                background-color: my-color(grey-light-100),
                color: my-color(grey-dark-900),
                border-color: my-color(grey-light-100),
                pseudo-classes: (
                    hover: (
                        background-color: my-color(grey-light-300)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-dark-900), 0 0 0 4px my-color(grey-light-100))
                    ),
                    active: (
                        outline: none,
                        background-color: my-color(grey-light-300)
                    )
                )
            ),
            // c-skin m-color-scheme-18
            color-scheme-18: (
                background-color: my-color(purple-500),
                color: my-color(grey-dark-900),
                border: solid 2px,
                border-color: my-color(grey-dark-900),
                pseudo-classes: (
                    hover: (
                        background-color: my-color(grey-light-300)
                    ),
                    focus: (
                        outline: none,
                        box-shadow: (0 0 0 2px my-color(grey-dark-900), 0 0 0 4px my-color(grey-light-100))
                    ),
                    active: (
                        outline: none,
                        background-color: my-color(grey-light-300)
                    )
                )
            ),

            // c-skin m-valid-invalid-1
            valid-invalid-1: (
                pseudo-classes: (
                    valid: (
                        border-color: my-color(green-500),
                        border-width: 2px,
                        pseudo-elements: (
                            before: (
                                selector: '~ * .hint',
                                content: '\e932',
                                color: my-color(green-500)
                            )
                        )
                    ),
                    invalid: (
                        border-color: my-color(red-500),
                        border-width: 2px,
                        pseudo-elements: (
                            before: (
                                selector: '~ * .hint',
                                content: '\e934',
                                color: my-color(red-500)
                            )
                        )
                    )
                )
            ),
            // c-skin m-disabled
            disabled: (
                pointer-events: none,
                color: grey,
                background-color: lightgrey
            ),
            // COLORS
            c-yellow-100: (
                color: my-color(yellow-100)
            ),
            c-yellow-200: (
                color: my-color(yellow-200)
            ),
            c-yellow-300: (
                color: my-color(yellow-300)
            ),
            c-yellow-400: (
                color: my-color(yellow-400)
            ),
            c-yellow-500: (
                color: my-color(yellow-500)
            ),
            c-yellow-600: (
                color: my-color(yellow-600)
            ),
            c-yellow-700: (
                color: my-color(yellow-700)
            ),
            c-yellow-800: (
                color: my-color(yellow-800)
            ),
            c-yellow-900: (
                color: my-color(yellow-900)
            ),
            c-orange-100: (
                color: my-color(orange-100)
            ),
            c-orange-200: (
                color: my-color(orange-200)
            ),
            c-orange-300: (
                color: my-color(orange-300)
            ),
            c-orange-400: (
                color: my-color(orange-400)
            ),
            c-orange-500: (
                color: my-color(orange-500)
            ),
            c-orange-600: (
                color: my-color(orange-600)
            ),
            c-orange-700: (
                color: my-color(orange-700)
            ),
            c-orange-800: (
                color: my-color(orange-800)
            ),
            c-orange-900: (
                color: my-color(orange-900)
            ),
            c-red-100: (
                color: my-color(red-100)
            ),
            c-red-200: (
                color: my-color(red-200)
            ),
            c-red-300: (
                color: my-color(red-300)
            ),
            c-red-400: (
                color: my-color(red-400)
            ),
            c-red-500: (
                color: my-color(red-500)
            ),
            c-red-600: (
                color: my-color(red-600)
            ),
            c-red-700: (
                color: my-color(red-700)
            ),
            c-red-800: (
                color: my-color(red-800)
            ),
            c-red-900: (
                color: my-color(red-900)
            ),
            c-lime-100: (
                color: my-color(lime-100)
            ),
            c-lime-200: (
                color: my-color(lime-200)
            ),
            c-lime-300: (
                color: my-color(lime-300)
            ),
            c-lime-400: (
                color: my-color(lime-400)
            ),
            c-lime-500: (
                color: my-color(lime-500)
            ),
            c-lime-600: (
                color: my-color(lime-600)
            ),
            c-lime-700: (
                color: my-color(lime-700)
            ),
            c-lime-800: (
                color: my-color(lime-800)
            ),
            c-lime-900: (
                color: my-color(lime-900)
            ),
            c-green-100: (
                color: my-color(green-100)
            ),
            c-green-200: (
                color: my-color(green-200)
            ),
            c-green-300: (
                color: my-color(green-300)
            ),
            c-green-400: (
                color: my-color(green-400)
            ),
            c-green-500: (
                color: my-color(green-500)
            ),
            c-green-600: (
                color: my-color(green-600)
            ),
            c-green-700: (
                color: my-color(green-700)
            ),
            c-green-800: (
                color: my-color(green-800)
            ),
            c-green-900: (
                color: my-color(green-900)
            ),
            c-blue-100: (
                color: my-color(blue-100)
            ),
            c-blue-200: (
                color: my-color(blue-200)
            ),
            c-blue-300: (
                color: my-color(blue-300)
            ),
            c-blue-400: (
                color: my-color(blue-400)
            ),
            c-blue-500: (
                color: my-color(blue-500)
            ),
            c-blue-600: (
                color: my-color(blue-600)
            ),
            c-blue-700: (
                color: my-color(blue-700)
            ),
            c-blue-800: (
                color: my-color(blue-800)
            ),
            c-blue-900: (
                color: my-color(blue-900)
            ),
            c-purple-100: (
                color: my-color(purple-100)
            ),
            c-purple-200: (
                color: my-color(purple-200)
            ),
            c-purple-300: (
                color: my-color(purple-300)
            ),
            c-purple-400: (
                color: my-color(purple-400)
            ),
            c-purple-500: (
                color: my-color(purple-500)
            ),
            c-purple-600: (
                color: my-color(purple-600)
            ),
            c-purple-700: (
                color: my-color(purple-700)
            ),
            c-purple-800: (
                color: my-color(purple-800)
            ),
            c-purple-900: (
                color: my-color(purple-900)
            ),
            c-grey-light-100: (
                color: my-color(grey-light-100)
            ),
            c-grey-light-200: (
                color: my-color(grey-light-200)
            ),
            c-grey-light-300: (
                color: my-color(grey-light-300)
            ),
            c-grey-light-400: (
                color: my-color(grey-light-400)
            ),
            c-grey-light-500: (
                color: my-color(grey-light-500)
            ),
            c-grey-light-600: (
                color: my-color(grey-light-600)
            ),
            c-grey-light-700: (
                color: my-color(grey-light-700)
            ),
            c-grey-light-800: (
                color: my-color(grey-light-800)
            ),
            c-grey-light-900: (
                color: my-color(grey-light-900)
            ),
            c-grey-dark-100: (
                color: my-color(grey-dark-100)
            ),
            c-grey-dark-200: (
                color: my-color(grey-dark-200)
            ),
            c-grey-dark-300: (
                color: my-color(grey-dark-300)
            ),
            c-grey-dark-400: (
                color: my-color(grey-dark-400)
            ),
            c-grey-dark-500: (
                color: my-color(grey-dark-500)
            ),
            c-grey-dark-600: (
                color: my-color(grey-dark-600)
            ),
            c-grey-dark-700: (
                color: my-color(grey-dark-700)
            ),
            c-grey-dark-800: (
                color: my-color(grey-dark-800)
            ),
            c-grey-dark-900: (
                color: my-color(grey-dark-900)
            ),
            // BACKGROUND COLOR
            bc-yellow-100: (
                background-color: my-color(yellow-100)
            ),
            bc-yellow-200: (
                background-color: my-color(yellow-200)
            ),
            bc-yellow-300: (
                background-color: my-color(yellow-300)
            ),
            bc-yellow-400: (
                background-color: my-color(yellow-400)
            ),
            bc-yellow-500: (
                background-color: my-color(yellow-500)
            ),
            bc-yellow-600: (
                background-color: my-color(yellow-600)
            ),
            bc-yellow-700: (
                background-color: my-color(yellow-700)
            ),
            bc-yellow-800: (
                background-color: my-color(yellow-800)
            ),
            bc-yellow-900: (
                background-color: my-color(yellow-900)
            ),
            bc-orange-100: (
                background-color: my-color(orange-100)
            ),
            bc-orange-200: (
                background-color: my-color(orange-200)
            ),
            bc-orange-300: (
                background-color: my-color(orange-300)
            ),
            bc-orange-400: (
                background-color: my-color(orange-400)
            ),
            bc-orange-500: (
                background-color: my-color(orange-500)
            ),
            bc-orange-600: (
                background-color: my-color(orange-600)
            ),
            bc-orange-700: (
                background-color: my-color(orange-700)
            ),
            bc-orange-800: (
                background-color: my-color(orange-800)
            ),
            bc-orange-900: (
                background-color: my-color(orange-900)
            ),
            bc-red-100: (
                background-color: my-color(red-100)
            ),
            bc-red-200: (
                background-color: my-color(red-200)
            ),
            bc-red-300: (
                background-color: my-color(red-300)
            ),
            bc-red-400: (
                background-color: my-color(red-400)
            ),
            bc-red-500: (
                background-color: my-color(red-500)
            ),
            bc-red-600: (
                background-color: my-color(red-600)
            ),
            bc-red-700: (
                background-color: my-color(red-700)
            ),
            bc-red-800: (
                background-color: my-color(red-800)
            ),
            bc-red-900: (
                background-color: my-color(red-900)
            ),
            bc-lime-100: (
                background-color: my-color(lime-100)
            ),
            bc-lime-200: (
                background-color: my-color(lime-200)
            ),
            bc-lime-300: (
                background-color: my-color(lime-300)
            ),
            bc-lime-400: (
                background-color: my-color(lime-400)
            ),
            bc-lime-500: (
                background-color: my-color(lime-500)
            ),
            bc-lime-600: (
                background-color: my-color(lime-600)
            ),
            bc-lime-700: (
                background-color: my-color(lime-700)
            ),
            bc-lime-800: (
                background-color: my-color(lime-800)
            ),
            bc-lime-900: (
                background-color: my-color(lime-900)
            ),
            bc-green-100: (
                background-color: my-color(green-100)
            ),
            bc-green-200: (
                background-color: my-color(green-200)
            ),
            bc-green-300: (
                background-color: my-color(green-300)
            ),
            bc-green-400: (
                background-color: my-color(green-400)
            ),
            bc-green-500: (
                background-color: my-color(green-500)
            ),
            bc-green-600: (
                background-color: my-color(green-600)
            ),
            bc-green-700: (
                background-color: my-color(green-700)
            ),
            bc-green-800: (
                background-color: my-color(green-800)
            ),
            bc-green-900: (
                background-color: my-color(green-900)
            ),
            bc-blue-100: (
                background-color: my-color(blue-100)
            ),
            bc-blue-200: (
                background-color: my-color(blue-200)
            ),
            bc-blue-300: (
                background-color: my-color(blue-300)
            ),
            bc-blue-400: (
                background-color: my-color(blue-400)
            ),
            bc-blue-500: (
                background-color: my-color(blue-500)
            ),
            bc-blue-600: (
                background-color: my-color(blue-600)
            ),
            bc-blue-700: (
                background-color: my-color(blue-700)
            ),
            bc-blue-800: (
                background-color: my-color(blue-800)
            ),
            bc-blue-900: (
                background-color: my-color(blue-900)
            ),
            bc-purple-100: (
                background-color: my-color(purple-100)
            ),
            bc-purple-200: (
                background-color: my-color(purple-200)
            ),
            bc-purple-300: (
                background-color: my-color(purple-300)
            ),
            bc-purple-400: (
                background-color: my-color(purple-400)
            ),
            bc-purple-500: (
                background-color: my-color(purple-500)
            ),
            bc-purple-600: (
                background-color: my-color(purple-600)
            ),
            bc-purple-700: (
                background-color: my-color(purple-700)
            ),
            bc-purple-800: (
                background-color: my-color(purple-800)
            ),
            bc-purple-900: (
                background-color: my-color(purple-900)
            ),
            bc-grey-light-100: (
                background-color: my-color(grey-light-100)
            ),
            bc-grey-light-200: (
                background-color: my-color(grey-light-200)
            ),
            bc-grey-light-300: (
                background-color: my-color(grey-light-300)
            ),
            bc-grey-light-400: (
                background-color: my-color(grey-light-400)
            ),
            bc-grey-light-500: (
                background-color: my-color(grey-light-500)
            ),
            bc-grey-light-600: (
                background-color: my-color(grey-light-600)
            ),
            bc-grey-light-700: (
                background-color: my-color(grey-light-700)
            ),
            bc-grey-light-800: (
                background-color: my-color(grey-light-800)
            ),
            bc-grey-light-900: (
                background-color: my-color(grey-light-900)
            ),
            bc-grey-dark-100: (
                background-color: my-color(grey-dark-100)
            ),
            bc-grey-dark-200: (
                background-color: my-color(grey-dark-200)
            ),
            bc-grey-dark-300: (
                background-color: my-color(grey-dark-300)
            ),
            bc-grey-dark-400: (
                background-color: my-color(grey-dark-400)
            ),
            bc-grey-dark-500: (
                background-color: my-color(grey-dark-500)
            ),
            bc-grey-dark-600: (
                background-color: my-color(grey-dark-600)
            ),
            bc-grey-dark-700: (
                background-color: my-color(grey-dark-700)
            ),
            bc-grey-dark-800: (
                background-color: my-color(grey-dark-800)
            ),
            bc-grey-dark-900: (
                background-color: my-color(grey-dark-900)
            ),
            brad-1: (
                border-radius: my-border-radius(1)
            ),
            brad-2: (
                border-radius: my-border-radius(2)
            ),
            brad-3: (
                border-radius: my-border-radius(3)
            ),
            brad-4: (
                border-radius: my-border-radius(4)
            ),
            brad-5: (
                border-radius: my-border-radius(5)
            ),
            brad-6: (
                border-radius: my-border-radius(6)
            ),
            brad-7: (
                border-radius: my-border-radius(7)
            ),
            brad-8: (
                border-radius: my-border-radius(8)
            ),
            brad-9: (
                border-radius: my-border-radius(9)
            ),
            brad-10: (
                border-radius: my-border-radius(10)
            ),
            bradtl-1: (
                border-top-left-radius: my-border-radius(1)
            ),
            bradtl-2: (
                border-top-left-radius: my-border-radius(2)
            ),
            bradtl-3: (
                border-top-left-radius: my-border-radius(3)
            ),
            bradtl-4: (
                border-top-left-radius: my-border-radius(4)
            ),
            bradtl-5: (
                border-top-left-radius: my-border-radius(5)
            ),
            bradtl-6: (
                border-top-left-radius: my-border-radius(6)
            ),
            bradtl-7: (
                border-top-left-radius: my-border-radius(7)
            ),
            bradtl-8: (
                border-top-left-radius: my-border-radius(8)
            ),
            bradtl-9: (
                border-top-left-radius: my-border-radius(9)
            ),
            bradtr-1: (
                border-top-right-radius: my-border-radius(1)
            ),
            bradtr-2: (
                border-top-right-radius: my-border-radius(2)
            ),
            bradtr-3: (
                border-top-right-radius: my-border-radius(3)
            ),
            bradtr-4: (
                border-top-right-radius: my-border-radius(4)
            ),
            bradtr-5: (
                border-top-right-radius: my-border-radius(5)
            ),
            bradtr-6: (
                border-top-right-radius: my-border-radius(6)
            ),
            bradtr-7: (
                border-top-right-radius: my-border-radius(7)
            ),
            bradtr-8: (
                border-top-right-radius: my-border-radius(8)
            ),
            bradtr-9: (
                border-top-right-radius: my-border-radius(9)
            ),
            bradbr-1: (
                border-bottom-right-radius: my-border-radius(1)
            ),
            bradbr-2: (
                border-bottom-right-radius: my-border-radius(2)
            ),
            bradbr-3: (
                border-bottom-right-radius: my-border-radius(3)
            ),
            bradbr-4: (
                border-bottom-right-radius: my-border-radius(4)
            ),
            bradbr-5: (
                border-bottom-right-radius: my-border-radius(5)
            ),
            bradbr-6: (
                border-bottom-right-radius: my-border-radius(6)
            ),
            bradbr-7: (
                border-bottom-right-radius: my-border-radius(7)
            ),
            bradbr-8: (
                border-bottom-right-radius: my-border-radius(8)
            ),
            bradbr-9: (
                border-bottom-right-radius: my-border-radius(9)
            ),
            bradbl-1: (
                border-bottom-left-radius: my-border-radius(1)
            ),
            bradbl-2: (
                border-bottom-left-radius: my-border-radius(2)
            ),
            bradbl-3: (
                border-bottom-left-radius: my-border-radius(3)
            ),
            bradbl-4: (
                border-bottom-left-radius: my-border-radius(4)
            ),
            bradbl-5: (
                border-bottom-left-radius: my-border-radius(5)
            ),
            bradbl-6: (
                border-bottom-left-radius: my-border-radius(6)
            ),
            bradbl-7: (
                border-bottom-left-radius: my-border-radius(7)
            ),
            bradbl-8: (
                border-bottom-left-radius: my-border-radius(8)
            ),
            bradbl-9: (
                border-bottom-left-radius: my-border-radius(9)
            ),
            bs-1: (
                box-shadow: my-shadow(1)
            ),
            bs-2: (
                box-shadow: my-shadow(2)
            ),
            bs-3: (
                box-shadow: my-shadow(3)
            ),
            bs-4: (
                box-shadow: my-shadow(4)
            ),
            bs-5: (
                box-shadow: my-shadow(5)
            ),
            bs-6: (
                box-shadow: my-shadow(6)
            )
        )
    )
);